import React from 'react';
//import VerificationForm from './VerificationForm';
import VerificationForm from './VerificationForm2';
import './App.css';

const App = () => {
  return (
    <div className='app-container'>
      <img src="/logo.png" alt="Header Image" style={{ height: '115px' }} />
      <img src="/menu-bg.png" alt="Header Image" style={{ width: '100%', height: 'auto' }} />
      
      <div>
        <h1 className='verification-page-title'>Meghalaya Home Guard Recruitment 2024</h1>
        <VerificationForm />
      </div>
      
    </div>
  );
};

<img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" className="logo" />

export default App;
