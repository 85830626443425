import React, { useState, useEffect } from 'react';
import './VerificationForm.css';

const VerificationForm = () => {
  const [appno, setAppno] = useState('');
  const [captchaAnswer, setCaptchaAnswer] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [captchaImage, setCaptchaImage] = useState('');

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    const operator = ['+', '-', '*'][Math.floor(Math.random() * 3)];
    const question = `What is ${num1} ${operator} ${num2}?`;
    const answer = eval(`${num1} ${operator} ${num2}`);
    setCaptchaAnswer(answer.toString());

    const canvas = document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 50;
    const ctx = canvas.getContext('2d');
    ctx.font = '20px Arial';
    ctx.fillText(question, 10, 30);
    const dataURL = canvas.toDataURL();
    setCaptchaImage(dataURL);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (appno && userAnswer === captchaAnswer) {
      try {
        const response = await fetch('https://meghhg2024.admitcard.apply-gov.in/api/verify', {
        //const response = await fetch('http://localhost:3007/verify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ appno: appno}),
        });
        const data = await response.json();
        console.log(data.filepath);
        
        if (data.filepath) {
            // Redirect to VerificationResult.pdf upon successful verification
            window.location.href = "cards/"+data.filepath+".pdf";
          } else {
            alert('Verification failed. Please check the entered email and Date of Birth.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      alert('Please enter Application Number and the correct answer to the CAPTCHA.');
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <label>
          Application Number:
          <input
            type="text"
            value={appno}
            onChange={(e) => setAppno(e.target.value)}
            required
          />
        </label>
        <label>
            CAPTCHA:
          {captchaImage && <img src={captchaImage} alt="CAPTCHA" />}
          <input
            type="text"
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default VerificationForm;
